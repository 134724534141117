import React, { useCallback, useState, useEffect, useRef } from "react";
import { CheckIcon } from "@100mslive/react-icons";
import { flexCenter, styled, Flex, Label, Checkbox, Text, Button } from "@100mslive/roomkit-react";
import PreviewJoin from "./PreviewJoin";
import PreviewName from "./PreviewName";
import { useAVToggle, usePreviewJoin } from "@100mslive/react-sdk";
import { UserPreferencesKeys, defaultPreviewPreference, useUserPreferences } from "../hooks/useUserPreferences";
import { ScreeningContext } from "../../context/ScreeningContext";
import Check from "../../icons/Check";
import BlueCheck from "../../icons/BlueCheck";
import CrossMark from "../../icons/CrossMark";
import { HMSVirtualBackgroundPlugin } from '@100mslive/hms-virtual-background';
import { useHMSStore, useHMSActions, selectIsLocalVideoPluginPresent, useDevices } from '@100mslive/react-sdk';
import Modal from "../Notifications/Modal"
import SettingsModal from "../Settings/SettingsModal";
import InterviewInstructionsModal from "../InterviewInstructionsModal";

const PreviewContainer = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  asRole,
}) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const { screeningData } = React.useContext(ScreeningContext);
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const { allDevices } = useDevices();
  const { videoInput } = allDevices;
  const [name, setName] = useState(screeningData.developerName);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const virtualBackground = new HMSVirtualBackgroundPlugin('blur');
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
      speakerAutoSelectionBlacklist: ["Yeti Stereo Microphone"],
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
    asRole,
  });

  const savePreferenceAndJoin = useCallback(async () => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    try {
    await hmsActions.setAudioSettings({
      advanced: [
        { noiseSuppression: true }, 
        { echoCancellation: true },
        { autoGainControl: true }
      ]
    });
    console.log("✅ Cancelación de ruido activada");
    join();
    onJoin && onJoin();
  } catch (error) {
    console.error("❌ Error al configurar el audio:", error);
  }
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const isVirtualBackgroundEnabled = useHMSStore(
    selectIsLocalVideoPluginPresent(virtualBackground.getName())
  );
  const hmsActions = useHMSActions();

  const [isRecording, setIsRecording] = useState(false);
  const [audioLevel, setAudioLevel] = useState(0);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [recordTime, setRecordTime] = useState(0);
  const audioStreamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioRef = useRef(null);
  const analyserRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioContextRef = useRef(null);
  const timerRef = useRef(null);

  const styles = {
    container: {
      fontFamily: 'Nunito, sans-serif',
      backgroundColor: 'white',
      border: '2px solid #E5E7EB',
      borderRadius: '8px',
      width: '95%',
      padding: '16px',
      boxSizing: 'border-box',
      marginTop: '15px',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    icon: {
      marginRight: '10px',
      color: '#5186ED',
    },
    title: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#1f2937',
      fontFamily: 'Nunito'
    },
    description: {
      fontSize: '14px',
      color: '#6b7280',
      marginBottom: '15px',
      fontFamily: 'Nunito'
    },
    buttonContainer: {
      display: 'flex',
      gap: '10px',
      marginTop: '10px',
    },
    button: {
      padding: '10px',
      backgroundColor: '#5186ED',
      color: 'white',
      border: 'none',
      borderRadius: '8px',
      fontFamily: 'Nunito, sans-serif',
      fontWeight: '600',
      fontSize: '14px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    disabledButton: {
      backgroundColor: '#E5E7EB',
      color: '#9CA3AF',
      cursor: 'not-allowed',
    },
    volumeBar: {
      width: '100%',
      height: '8px',
      backgroundColor: '#e5e7eb',
      borderRadius: '9999px',
      overflow: 'hidden',
      marginBottom: '15px',
    },
    volumeIndicator: {
      height: '100%',
      backgroundColor: '#5186ED',
      transition: 'width 0.1s ease-in-out',
    }
  };

  useEffect(() => {
    const applyVirtualBackground = async () => {
      try {
        if (!isVirtualBackgroundEnabled) {
          // Recommended value
          const pluginFrameRate = 7;
          await hmsActions.addPluginToVideoTrack(virtualBackground, pluginFrameRate);
        }
      } catch (err) {
        console.log('virtual background failure - ', isVirtualBackgroundEnabled, err);
      }
    };

    applyVirtualBackground();
  }, [hmsActions, isVirtualBackgroundEnabled, virtualBackground]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  useEffect(() => {
    if (videoInput?.length > 1) {
      setIsModalOpen(true);
    }
  }, [videoInput]);

  useEffect(() => {
  const hasSeenModal = localStorage.getItem("hasSeenInterviewModal");
    if (!hasSeenModal) {
        setIsFirstModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setIsFirstModalOpen(false);
    localStorage.setItem("hasSeenInterviewModal", "true");
  };

  const startRecording = async () => {
    try {
        setRecordTime(0); // Reset counter
        timerRef.current = setInterval(() => {
        setRecordTime((prev) => prev + 1);
        }, 1000);
      // Limpiar grabaciones previas
      audioChunksRef.current = [];
      
      // Obtener stream de audio
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStreamRef.current = stream;

      // Configurar grabación
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
        clearInterval(timerRef.current);
        setRecordedAudio(URL.createObjectURL(audioBlob));
      };

      mediaRecorder.start();
      setIsRecording(true);

      // Configurar análisis de audio
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      const dataArray = new Uint8Array(analyser.frequencyBinCount);

      analyser.fftSize = 256;
      microphone.connect(analyser);
      
      analyserRef.current = analyser;

      const updateVolume = () => {
        if (analyserRef.current && isRecording) {
          analyserRef.current.getByteFrequencyData(dataArray);

          const sum = dataArray.reduce((acc, val) => acc + val, 0);
          const avgVolume = sum / dataArray.length;
          const adjustedVolume = Math.min(Math.max((avgVolume * 2), 5), 100);

          setAudioLevel(adjustedVolume);
          requestAnimationFrame(updateVolume);
        }
      };

      updateVolume();

      // Auto stop after 10 seconds
      setTimeout(() => {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stop();
          setIsRecording(false);
        }
      }, 10000);

    } catch (error) {
      console.error("Error starting microphone test:", error);
      setIsRecording(false);
    }
  };

  const playRecording = () => {
    if (recordedAudio && audioRef.current) {
      audioRef.current.play();
    }
  };

  const resetRecording = () => {
    setRecordedAudio(null);
    setIsRecording(false);
    audioChunksRef.current = [];

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach(track => track.stop());
      audioStreamRef.current = null;
    }

    if (analyserRef.current) {
      analyserRef.current.disconnect();
      analyserRef.current = null;
    }

    setAudioLevel(0);
  };

useEffect(() => {
    let cleanup = () => {};

    const setupAudioMonitoring = async () => {
      try {
        // Request microphone access
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioStreamRef.current = stream;

        // Create audio context
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContextRef.current = audioContext;

        // Create analyzer
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        
        analyser.fftSize = 256;
        microphone.connect(analyser);
        analyserRef.current = analyser;

        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        // Continuous volume monitoring function
        const updateVolume = () => {
          if (analyserRef.current) {
            analyserRef.current.getByteFrequencyData(dataArray);

            const sum = dataArray.reduce((acc, val) => acc + val, 0);
            const avgVolume = sum / dataArray.length;
            const adjustedVolume = Math.min(Math.max((avgVolume * 2), 5), 100);

            setAudioLevel(adjustedVolume);
            
            // Continue monitoring
            cleanup = requestAnimationFrame(updateVolume);
          }
        };

        // Start monitoring
        updateVolume();

      } catch (error) {
        console.error("Error setting up audio monitoring:", error);
      }
    };

    // Setup audio monitoring
    setupAudioMonitoring();

    // Cleanup function
    return () => {
      // Cancel animation frame
      if (cleanup) cancelAnimationFrame(cleanup);

      // Stop audio tracks
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach(track => track.stop());
      }

      // Close audio context
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }

      // Disconnect analyser
      if (analyserRef.current) {
        analyserRef.current.disconnect();
      }
    };
  }, []);

  return (
    <Flex
      css={{
        position: "relative",
        width: "100%",
        flexDirection: "column",
        px: "50px",
        bg: "white",
        py: "50px",
        "@sm": {
          height: "fit-content",
          padding: "50px"
        }
      }}>
      <Flex
        css={{
          position: "relative",
          width: "100%",
          flexDirection: "row",
          gap: "35px",
          "@sm": {
            flexDirection: "column",
            mt: "1rem",
          }
        }}>
        <Flex 
        justify="center"
        css={{
          justifyContent: "center",
          position: "relative",
          width: "50%",
          flexDirection: "column",
          "@sm": {
            width: "100%",
          }
        }}>
          <Flex
            justify="center"
            align="center"
            css={{
              position: "relative",
              width: "100%",
              height: "11rem",
              flexDirection: "column"
            }}>
            <Flex
              css={{
                fontSize: "$h5",
                fontWeight: "700",
                fontFamily: "Nunito",
                "@sm": {
                  fontSize: "$h6",
                }
              }}>
              You’re about to start
            </Flex>
            <Flex
              style={{display: "inline"}}
              css={{
                width: "100%",
                color: '#545964',
                fontSize: '1rem',
                textAlign: "center",
                mt: '0.75rem',
                fontFamily: 'Nunito',
                fontWeight: '600',
                "@sm": { width: "100%", mb: '1.25rem', fontSize: "$xs" },
              }}>
              The next step is to complete a quick <b>video</b> interview with our AI assistant. Please check these recommendations before your interview: take time to answer every question, and remember that the interview could last around 20 minutes in total.
            </Flex>
          </Flex>
          <PreviewJoin
            initialName={initialName}
            skipPreview={skipPreview}
            env={env}
            onJoin={onJoin}
            token={token}
            asRole={asRole}
          />

<div style={styles.container}>
      <div style={styles.header}>
        <svg 
          style={styles.icon} 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2"
        >
          <path d="M12 2a3 3 0 0 0-3 3v6a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3z"/>
          <path d="M19 10v2a7 7 0 0 1-14 0v-2"/>
          <line x1="12" y1="19" x2="12" y2="22"/>
          <line x1="8" y1="22" x2="16" y2="22"/>
        </svg>
        <div style={styles.title}>Microphone Test</div>
      </div>
      <div style={styles.description}>
        Click "Start Recording" and speak clearly for a few seconds. 
        A visual indicator will show your volume level. 
        Recording stops automatically after 10 seconds.
      </div>
       <div style={styles.volumeBar}>
        <div 
            style={{
            ...styles.volumeIndicator,
            width: `${audioLevel}%`
            }}
        />
        </div>

      <Flex css={{
            width:"100%",
            justifyContent: "space-between"
            }}>
        {!recordedAudio ? (
        <button 
          style={styles.button}
          onClick={startRecording}
          disabled={isRecording}
        >
          {isRecording ? 'Recording...' : 'Start Recording'}
        </button>
      ) : (
        <div style={styles.buttonContainer}>
          <button 
            style={styles.button}
            onClick={playRecording}
          >
            Play Recording
          </button>
          <button 
            style={{...styles.button, backgroundColor: '#FDB7B7', color: 'white'}}
            onClick={resetRecording}
          >
            Reset
          </button>
        </div>
      )}
      <p style={{ fontSize: '14px', color: isRecording ? '#5186ED' : '#9CA3AF', textAlign: 'right' }}>
        Recording Time: {recordTime}s
        </p>
      </Flex> 
      <audio 
        ref={audioRef} 
        src={recordedAudio} 
        style={{ display: 'none' }} 
      />
    </div>

          
        </Flex>
        <Flex 
        justify="center"
        css={{
          width: "50%",
          flexDirection: "column",
          "@sm": {
            width: "100%"
          }
        }}>
          {/* <Flex css={{
            width: "100%",
            direction: "row",
            gap: "24px",
            "@sm": {
              flexDirection: "column",
            }
          }}>
            <Flex css={{
              width: "50%",
              direction: "row",
              "@sm": {
                width: "100%",
              }
            }}>
              <Flex css={{
                border: '2px solid #A3E1AD', // Color de borde simbiosis-greenscreening
                minHeight: '13rem', // Aproximadamente equivalente a h-52
                borderRadius: '0.5rem', // Aproximadamente equivalente a rounded-lg
                width: '100%',
                flexDirection: "column",
                height: 'auto',
              }}>
                <Flex
                  justify="center"
                  css={{
                    backgroundColor: '#A3E1AD', // Color de fondo simbiosis-greenscreening
                    textAlign: 'center',
                    padding: '0.5rem',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    "@sm": {
                      fontSize: "$sm"
                    }
                  }}>
                  Recommendations
                </Flex>
                <Flex
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '0.875rem',
                    padding: '16px',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                  }}
                >
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <Check />
                    </Flex>
                    <Text css={{
                      display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                      fontWeight: '600',
                    }}>
                      Activate your Camera and Microphone permissions before start
                    </Text>
                  </Flex>
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <Check />
                    </Flex>
                    <Text css={{
                      display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                      fontWeight: '600',
                    }}>
                      Check your internet connection
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <Check />
                    </Flex>
                    <Text css={{
                      display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                      fontWeight: '600',
                    }}>
                      Make sure you are in a bright and quiet space
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex css={{
              width: "50%",
              direction: "row",
              "@sm": {
                width: "100%",
              }
            }}>
              <Flex css={{
                border: '2px solid #FDB7B7',
                minHeight: '13rem',
                borderRadius: '0.5rem',
                width: '100%',
                flexDirection: "column",
                height: 'auto',
              }}>
                <Flex
                  justify="center"
                  css={{
                    backgroundColor: '#FDB7B7',
                    textAlign: 'center',
                    padding: '0.5rem',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                    "@sm": {
                      fontSize: "$sm"
                    }
                  }}>
                  Avoid this
                </Flex>
                <Flex
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    fontFamily: 'Nunito',
                    fontWeight: '600',
                  }}
                >
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <CrossMark />
                    </Flex>
                    <Text css={{
                      display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                      fontWeight: '600',
                    }}>
                      Changing your browser tab. We want you to focus on the interview
                    </Text>
                  </Flex>
                  <Flex align="center"
                    css={{
                      mb: "16px",
                    }}>
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <CrossMark />
                    </Flex>
                    <Text css={{
                      display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                      fontWeight: '600',
                    }}>
                      Consulting AI tools. We want to evaluate your knowledge and skills
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Flex css={{
                      width: "16px",
                      height: "16px",
                      mr: "16px",
                    }}>
                      <CrossMark />
                    </Flex>
                    <Text css={{
                      display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                      fontWeight: '600',
                    }}>
                      Taking this call in noisy and public spaces.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex> */}
          <Flex css={{
            width: "100%",
            flexDirection:"column",
            justifyContent:"center",
            mt: "2rem",
            "@sm": {
              width: "100%",
            }
          }}>
            <Flex css={{
              border: '2px solid #5186ED',
              minHeight: '13rem',
              borderRadius: '0.5rem',
              width: '100%',
              flexDirection: "column",
              height: 'auto',
            }}>
              <Flex
                justify="center"
                css={{
                  backgroundColor: '#5186ED',
                  textAlign: 'center',
                  padding: '0.5rem',
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                  color: 'white',
                  "@sm": {
                    fontSize: "$sm"
                  }
                }}>
                About the process
              </Flex>
              <Flex
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '0.875rem',
                  padding: '16px',
                  fontFamily: 'Nunito',
                  fontWeight: '600',
                }}
              >
                <Flex align="center"
                  css={{
                    mb: "16px",
                  }}>
                  <Flex css={{
                    width: "16px",
                    height: "16px",
                    mr: "16px",
                  }}>
                    <BlueCheck />
                  </Flex>
                  <Text css={{
                    display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600',
                  }}>
                    Click 'Start Interview.' Recording begins automatically, with no option to start, pause, or stop manually. All responses are video-recorded, covering technical and behavioral questions for a complete skill assessment.
                  </Text>
                </Flex>
                <Flex align="center"
                  css={{
                    mb: "16px",
                  }}>
                  <Flex css={{
                    width: "16px",
                    height: "16px",
                    mr: "16px",
                  }}>
                    <BlueCheck />
                  </Flex>
                  <Text 
                  style={{
                    display: "inline"
                  }}
                  css={{
                    display: 'flex', fontSize: "0.875rem", color: "#545964", fontFamily: 'Nunito',
                    fontWeight: '600',
                  }}>
                    <b>Respond to each question on video. </b> Use "Next Question" to move forward. Complete all X questions to finish.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            align="center"
            justify="center"
            css={{
              flexDirection: "column",
            }}>
            <Flex
              align="center"
              css={{
                flexDirection: "row",
                py: "24px",
              }}>
              <input type="checkbox" style={{
                width: "24px",
                height: "24px",
                accentColor: "#5186ED",
              }}
                onChange={handleCheckboxChange}
              />
              <Label htmlFor="test" css={{
                display: 'flex',
                color: '#64748B',
                fontSize: '0.875rem',
                ml: "8px",
                fontFamily: 'Nunito',
                fontWeight: '600',
              }}>
                I meet the requirements mentioned to start this interview.
              </Label>
            </Flex>
            <PreviewName
              name={name}
              onJoin={savePreferenceAndJoin}
              enableJoin={enableJoin && isCheckboxChecked}
            />
          </Flex>
        </Flex>
      </Flex>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Multiple Cameras Detected"
      >
        <Text css={{ color: '#545964' }}>Multiple cameras have been detected. Please ensure that the correct camera is selected and turned on, as it is important for accurately assessing the screening.</Text>
        <Flex justify="center" css={{ marginTop: '20px', gap: '10px' }}>
          <Button style={{
            border: '2px solid #A6A8AC',
            color: '#A6A8AC',
          }} css={{
            width: "30%", bg: 'white!important', '&:hover': {
              bg: 'white!important',
            },
          }} onClick={() => setIsModalOpen(false)}>Close</Button>
          <Button css={{ width: "30%" }} onClick={() => {
            setIsSettingsModalOpen(true);
            setIsModalOpen(false);
          }}>Configure</Button>
        </Flex>
      </Modal>
      <SettingsModal
        open={isSettingsModalOpen}
        onOpenChange={setIsSettingsModalOpen}
      />
      <InterviewInstructionsModal open={isFirstModalOpen} onClose={handleCloseModal} />
    </Flex >
  );
};

const Container = styled("div", {
  width: "100%",
  heigth: "80%",
  flexDirection: "row",
});

export default PreviewContainer;