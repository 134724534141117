import React from "react";
import uploadedImage from "../images/screening-process.png";

export default function InterviewInstructionsModal({ open, onClose }) {
  if (!open) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <svg className="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
          </svg>
          <h2 className="modal-title">Getting Ready for Your Video Screening</h2>
        </div>

        <p className="modal-description">Complete these 4 simple steps to ensure a smooth video screening experience.</p>

        <div className="modal-body">
          {["Configure your devices", "Begin your interview", "Answer each question", "Move through all questions"].map((title, index) => (
            <div key={index} className="instruction">
                <div className="step-number">{index + 1}</div>
                <div className="step-content">
                <h3 className="step-title">{title}</h3>
                <p>
                    {index === 0 && "Allow browser permissions and ensure your camera and microphone work properly."}
                    {index === 1 && (
                        <>
                            Click 'Start Interview.'
                            <div className="recording-tooltip">
                            <svg className="info-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <circle cx="12" cy="12" r="10" strokeWidth="2" stroke="#1663c7" fill="white"/>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 16v-4m0-4h.01" stroke="#1663c7"/>
                            </svg>
                            Recording will start automatically when you click the button. No need to start/stop recording manually.
                            </div>
                        </>
                    )}
                    {index === 2 && (
                    <>
                        Each question will appear on screen. Speak your answer clearly. No need to type your responses.
                        <div className="image-container">
                        <img src={uploadedImage} alt="screening-process" className="modal-image" />
                        </div>
                    </>
                    )}
                    {index === 3 && "Click 'Next Question' to proceed. You will answer around 13-18 questions."}
                </p>
                </div>
            </div>
            ))}
        </div>

        <div className="modal-footer">
          <button className="btn" onClick={onClose}>Got it</button>
        </div>

        <style jsx>{`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(8px);
            padding: 10px;
            }

            .modal-container {
            overflow:auto;
            background: white;
            padding: 24px;
            border-radius: 12px;
            max-width: 600px;
            width: 90%;
            max-height: 85vh;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            }

            .modal-body {
            flex: 1;
            }

            .modal-footer {
            margin-top: 16px;
            text-align: center;
            padding-bottom: 10px;
            }
            .modal-header {
            display: flex;
            align-items: center;
            gap: 10px;
            padding-bottom: 5px;
            }

            .image-container {
            display: flex;
            margin-top: 8px;
            }
            .modal-title {
            font-size: 22px;
            font-weight: bold;
            color: #1f2937;
            padding: 0!important;
            margin: 0!important;
          }
          .modal-description {
            font-size: 14px;
            margin-bottom: 16px;
            color: #6b7280;
            padding: 0!important;
            margin-top: 0!important;
          }

            .modal-image {
            max-width: 60%;
            max-height: 160px;
            height: auto;
            border-radius: 8px;
            display: block;
            }

            .instruction {
            display: flex;
            align-items: flex-start;
            padding: 2px;
            border-radius: 8px;
            margin-bottom: 16px;
            }

            .step-number {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            background: #5186ED;
            color: white;
            border-radius: 50%;
            margin-right: 12px;
            flex-shrink: 0;
            }
            .step-content h3 {
            padding: 0!important;
            margin: 0!important;
            font-size: 16px;
            font-weight: bold;
            color: #1f2937;
          }
          .step-content p {
            margin-top: 5px!important;
            font-size: 14px;
            color: #374151;
          }

            .step-content {
            flex: 1;
            }

            .btn {
            background: #5186ED;
            color: white;
            padding: 10px 16px;
            border: none;
            border-radius: 8px;
            font-weight: bold;
            cursor: pointer;
            transition: all 0.3s ease;
            width: 100%;
            text-align: center;
            margin-top: 12px;
            }

            .btn:hover {
            background: #426dd0;
            }
            .icon {
            width: 32px;
            height: 32px;
            color: #5186ED;
          }
          .recording-tooltip {
            background: #f3f8ff;
            color: #1663c7;
            border-radius: 8px;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #c6d6f8;
            margin-top: 8px;
            }

            .info-icon {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            }
        `}</style>
      </div>
    </div>
  );
}